<template>
  <video
    v-if="reseted"
    class="video-js video-full"
    ref="video"
    oncontextmenu="return false"
    @loadeddata="onPlayerLoadeddata($event)"
  >
    <track
      v-for="(vttTrack, index) in trackList"
      :key="index"
      :kind="vttTrack.kind"
      :label="vttTrack.label"
      :src="vttTrack.src"
      :srcLang="vttTrack.srclang"
      :default="vttTrack.default"
    />
  </video>
</template>

<script>
import "video.js/dist/video-js.css";
import _videojs from "video.js";

const videojs = window.videojs || _videojs;

import "videojs-landscape-fullscreen";

export default {
  name: "video-player",
  props: {
    start: {
      type: Number,
      default: 0,
    },
    crossOrigin: {
      type: String,
      default: "anonymous",
    },
    playsinline: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    globalOptions: {
      type: Object,
      default: () => ({
        preload: "auto",

        controlBar: {
          playToggle: true,
          liveDisplay: false,
          currentTimeDisplay: true,
          progressControl: true,
          audioTrackButton: true,
          captionsButton: true,
          subsCapsButton: false,
          playbackRateMenuButton: true,
          remainingTimeDisplay: true,
          durationDisplay: true,
        },
        techOrder: ["html5"],
        plugins: {},
      }),
    },
    trackList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      player: null,
      reseted: true,
    };
  },
  methods: {
    initialize() {
      // videojs options
      const videoOptions = Object.assign({}, this.globalOptions, this.options);

      // playsinline (iOs, Android)
      if (this.playsinline) {
        this.$refs.video.setAttribute("playsinline", this.playsinline);
        this.$refs.video.setAttribute("webkit-playsinline", this.playsinline);
      }

      // crossorigin
      if (this.crossOrigin !== "") {
        this.$refs.video.crossOrigin = this.crossOrigin;
        this.$refs.video.setAttribute("crossorigin", this.crossOrigin);
      }

      // avoid error "VIDEOJS: ERROR: Unable to find plugin: __ob__"
      if (videoOptions.plugins) {
        delete videoOptions.plugins.__ob__;
      }

      // player
      const self = this;
      this.player = videojs(this.$refs.video, videoOptions, function () {
        // player readied
        self.$emit("ready", this);
      });

      // get audio tracks
      // let playerAudioTrackList = videoOptions.audioTrackList;
      // console.log("++ playerAudioTrackList:", playerAudioTrackList);
      // if (playerAudioTrackList.length > 0) {
      //   playerAudioTrackList.forEach((track) => {
      //     this.player.audioTracks().addTrack(
      //       // create a track object
      //       new videojs.AudioTrack(track)
      //     );
      //     console.log("++ track", track);
      //   });
      // }
      // console.log("+++ audioTracksObject:", this.audioTracksObject());
      // let audioTrackList = this.player.audioTracks();
      // // Listen to the "change" event.
      // audioTrackList.addEventListener("change", function () {
      //   // Log the currently enabled AudioTrack label.
      //   for (let i = 0; i < audioTrackList.length; i++) {
      //     let track = audioTrackList[i];
      //     if (track.enabled) {
      //       videojs.log(track.language, track.label);
      //       console.log(videojs.AudioTrack);
      //       return;
      //     }
      //   }
      // });

      // iOS fullscreen
      if (this.options.controls) {
        this.player.landscapeFullscreen({
          fullscreen: {
            enterOnRotate: true,
            alwaysInLandscapeMode: true,
            iOS: true,
          },
        });
      }

      // set volume
      this.player.volume(videoOptions.volume);

      // set fullscreen
      videoOptions.controlBar.fullscreenToggle = true;

      console.log("++++ player properties:", this.player);
    },

    onPlayerLoadeddata(player) {
      console.log("++++ player:", player);
      console.log("++++ this.player.audioTracks:", this.player.audioTracks());
      var audioTracks = this.player.audioTracks();
      // loop through audio tracks
      for (let i = 0; i < audioTracks.length; i++) {
        let track_language = audioTracks[i].language;
        console.log("+++ track_language:", track_language);
      }
    },
    audioTracksObject() {
      return this.player.audioTracks().tracks_;
    },
  },
  mounted() {
    if (!this.player) {
      this.initialize();
    }
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
