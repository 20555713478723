import { createRouter, createWebHistory } from 'vue-router'
import VideoPlayer from "@/views/VideojsWrapper"
// import VideoPlayer from "@/views/VideojsTest"
import VideoPlayerNotFound from "@/views/VideoPlayerNotFound"

const routes = [
  {
    path: '/',
    // component:VideoPlayerNotFound
    redirect: "/404"
  },
  {
    path: '/umvp',
    component: VideoPlayer,
    name: 'uvin-medialive-video-player',
    children: [
      {
        path: ":id",
        name: "uvin-medialive-video-player-video",
        component: VideoPlayer
      }
    ]
  },
  {
    path: "/404",
    name: "uvin-medialive-video-player-not-found",
    component: VideoPlayerNotFound
  },
  {
    path: "/:notFound(.*)",
    // component:VideoPlayerNotFound
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router