<template>
  <div class="video-player-container">
    <video-player
      class="video-player-obj"
      v-if="isVideoPlayerReady"
      ref="videoPlayer"
      :options="videoOptions"
      :playsinline="true"
      :trackList="trackList"
    >
    </video-player>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideojsPlayer.vue";
import EventService from "@/services/EventService.js";
import languageISOCode from "@/data/iso_language_code.json";

export default {
  components: {
    videoPlayer,
  },
  props: {},
  data() {
    return {
      textTrackList: [],
      defaultOriginalStr: "_original.",
      isVideoPlayerReady: false,
      languageISOCode,
      videoGuid: null,
      video: {
        videoId: "",
        deliverables: [],
        fileName: "",
        creationDate: "",
        creationDateUnixTimeStamp: 0,
        modificationDate: "",
        modificationDateUnixTimeStamp: "",
        userId: "",
        userName: "",
        audioTracks: [],
        thumbnails: [],
        subtitles: [],
        tags: [],
        views: 0,
        digitalEdition: true,
        metadata: [],
        attachments: [],
        markers: [],
        oldVideoId: 0,
        titlesearch: "",
        guid: "",
        duration: 0,
      },
      videoDeliverables: [],
      videoDeliverables_audio: [],
      videoDeliverables_video: [],
      videoDeliverables_video_Sorted: [],
      videoSubtitles: [],
      videoProperties: {},
      mainPoster: false,
    };
  },
  computed: {
    videoOptions() {
      return {
        width: this.videoProperties.width,
        height: this.videoProperties.height,
        sources: [
          {
            type: "video/mp4",
            src: this.videoProperties.videoSrc,
            withCredentials: false,
          },
        ],
        audioTrackList: this.videoProperties.audioTrackList,
        defaultSubtitle: this.videoProperties.defaultSubtitle,
        controls: this.videoProperties.controls,
        autoplay: this.videoProperties.autoplay,
        loop: this.videoProperties.loop,
        muted: this.videoProperties.muted,
        poster: this.mainPoster,
        volume: this.videoProperties.volume,
        controlBar: {
          fullscreenToggle: this.videoProperties.fullscreenToggle,
          pictureInPictureToggle: this.videoProperties.pictureInPictureToggle,
          volumePanel: {
            inline: false,
            volumeControl: {
              vertical: true,
            },
          },
        },
      };
    },
    trackList() {
      return this.textTrackList;
    },
  },
  methods: {
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    async getVideoDataFromAPI(referer) {
      console.log("++ referrer", referer);
      //get video guid
      this.videoGuid = this.$route.params.id;

      //get video object from API
      let apiResponseObj = await EventService.getVideoByGuid(this.videoGuid, referer);
      if (apiResponseObj.status != 200) {
        console.error(
          "+++ Get video by guid>> An error has occurred, please try again",
          apiResponseObj.status
        );
        this.$router.push({ name: "uvin-medialive-video-player-not-found" });
        return;
      }

      // error if video: {}
      if (this.isObjectEmpty(apiResponseObj.data)) {
        console.log("+++ video is empty", apiResponseObj.data);
        this.$router.push({ name: "uvin-medialive-video-player-not-found" });
        return;
      }

      //set video object data
      this.video = apiResponseObj.data;
      console.log("++++ this.video:", this.video);

      //get deliverables
      this.videoDeliverables = this.video.deliverables;

      //sort videoDeliverables
      this.videoDeliverables.sort(this.compareValuesObjKey("width", "desc"));
      console.log("++++ this.videoDeliverables_Sorted:", this.videoDeliverables);

      //separate this.videoDeliverables isAudio = true & isAudio = false
      for (let i = 0; i < this.videoDeliverables.length; i++) {
        if (this.videoDeliverables[i].isAudio) {
          this.videoDeliverables_audio.push(this.videoDeliverables[i]);
        } else {
          this.videoDeliverables_video.push(this.videoDeliverables[i]);
        }
      }
      console.log("++++ this.videoDeliverables_audio:", this.videoDeliverables_audio);
      console.log("++++ this.videoDeliverables_video:", this.videoDeliverables_video);

      //sort only video of deliverbles (but they are just sorted)
      this.videoDeliverables_video_Sorted = this.videoDeliverables_video.sort(
        this.compareValuesObjKey("width", "desc")
      );
      console.log(
        "++++ videoDeliverables_video_Sorted:",
        this.videoDeliverables_video_Sorted
      );

      //get subtitles
      console.log("++ this.video.subtitles:", this.video.subtitles);
      this.videoSubtitles = this.video.subtitles;
      this.videoSubtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1));
      console.log("++++ this.videoSubtitles:", this.videoSubtitles);

      //check video guid
      if (this.videoGuid == this.video.guid) {
        //get video properties
        console.log("++ video guid is ok");
        this.getVideoProperties();
      } else {
        this.$router.push({ name: "uvin-medialive-video-player-not-found" });
      }

      //get thumbnails, get video poster
      this.mainPoster = false;
      this.videoThumbnails = this.video.thumbnails;
      let thumbFind = this.videoThumbnails.find((el) => el.isDefault === true);
      if (thumbFind != undefined) {
        this.mainPoster = thumbFind.uri;
      }
    },
    getVideoProperties() {
      this.videoProperties = {
        videoSrc: "",
        audioTrackList: [],
        poster: this.mainPoster,
        width: "100%",
        height: "100%",
        autoplay: false,
        loop: false,
        controls: true,
        muted: false,
        volume: 1,
        fullscreenToggle: true,
        pictureInPictureToggle: true,
        defaultSubtitle: "",
      };
      if (!this.isObjectEmpty(this.video)) {
        //***************************************//
        //** QUERY STRING for video properties **//
        //***************************************//

        // autoplay
        // autoplay = false => ap = 0
        // autoplay = true => ap = 1
        if (this.$route.query.ap) {
          if (this.$route.query.ap == "1") {
            this.videoProperties.autoplay = true;
          } else if (this.$route.query.ap == "0") {
            this.videoProperties.autoplay = false;
          }
        }

        // loop
        // loop = false => lp = 0
        // loop = true => lp = 1
        if (this.$route.query.lp) {
          if (this.$route.query.lp == "1") {
            this.videoProperties.loop = true;
          } else if (this.$route.query.lp == "0") {
            this.videoProperties.loop = false;
          }
        }

        // muted / volume
        // muted = true => vol = 0
        // muted = false => vol = 0.1 / 1 (1=100%, 0.5 = 50%, 0 = 0%)
        if (this.$route.query.vol) {
          if (Number(this.$route.query.vol) == 0) {
            this.videoProperties.muted = true;
            this.videoProperties.volume = 0;
          } else {
            this.videoProperties.muted = false;
            this.videoProperties.volume = Number(this.$route.query.vol);
          }
        }

        // default subtitle
        // default subtitle => dst = it
        if (this.$route.query.dst) {
          if (this.$route.query.dst != "") {
            this.videoProperties.defaultSubtitle = this.$route.query.dst;
          }
        }

        // video filename
        // vfn = filename.mp4
        let videoSasUrl = "";
        if (this.$route.query.vfn) {
          if (this.$route.query.vfn != "") {
            videoSasUrl = this.getSasUrlByFilename(this.$route.query.vfn);
          } else {
            videoSasUrl = this.getSasUrlByFilename(this.defaultOriginalStr);
          }
        } else {
          videoSasUrl = this.getSasUrlByFilename(this.defaultOriginalStr);
        }

        // videoSrc
        this.videoProperties.videoSrc = videoSasUrl;

        // controls
        // controls = false => cts = 0
        // controls = true => cts = 1
        if (this.$route.query.cts) {
          if (this.$route.query.cts == "1") {
            this.videoProperties.controls = true;
          } else if (this.$route.query.cts == "0") {
            this.videoProperties.controls = false;
          }
        }

        // fullscreenToggle
        // fullscreenToggle = false => fs = 0
        // fullscreenToggle = true => fs = 1
        if (this.$route.query.fs) {
          if (this.$route.query.fs == "1") {
            this.videoProperties.fullscreenToggle = true;
          } else if (this.$route.query.fs == "0") {
            this.videoProperties.fullscreenToggle = false;
          }
        }

        // pictureInPictureToggle
        // pictureInPictureToggle = false => pip = 0
        // pictureInPictureToggle = true => pip = 1
        if (this.$route.query.pip) {
          if (this.$route.query.pip == "1") {
            this.videoProperties.pictureInPictureToggle = true;
          } else if (this.$route.query.pip == "0") {
            this.videoProperties.pictureInPictureToggle = false;
          }
        }
      }

      // subtitles, captions, texttracks
      // get track list
      this.getTextTrackList();

      //audio track list
      this.videoProperties.audioTrackList = [
        {
          id: "1",
          kind: "translation",
          label: "English",
          language: "en",
        },
        {
          id: "2",
          kind: "translation",
          label: "Italian",
          language: "it",
        },
      ];

      // enable video player
      this.isVideoPlayerReady = true;

      return this.videoProperties;
    },
    getTextTrackList() {
      this.textTrackList = [];
      this.videoSubtitles.forEach((textTrack) => {
        let element = this.languageISOCode.find((el) => el.isoCode === textTrack.lang);
        let defaultSt = false;
        if (element) {
          if (this.videoOptions.defaultSubtitle) {
            defaultSt = element.isoCode === this.videoOptions.defaultSubtitle;
          }
          this.textTrackList.push({
            kind: "subtitles",
            label: element.isoCode + " - " + element.language,
            srclang: element.isoCode,
            src: textTrack.uri,
            default: defaultSt,
          });
        }
      });
      console.warn(this.videoSubtitles);
    },
    getSasUrlByFilename(fileName) {
      let videoSasUrl = "";
      let tmpValueToFind = "";
      if (fileName == this.defaultOriginalStr) {
        videoSasUrl = this.getDeliverableVideoOriginal(
          this.videoDeliverables_video_Sorted,
          "fileName"
        ).sas_url;
      } else {
        tmpValueToFind = this.findValueByKeyInArray(
          this.videoDeliverables_video_Sorted,
          "fileName",
          fileName
        );
        if (!this.isObjectEmpty(tmpValueToFind)) {
          videoSasUrl = tmpValueToFind.sas_url;
        } else {
          videoSasUrl = this.getDeliverableVideoOriginal(
            this.videoDeliverables_video_Sorted,
            "fileName"
          ).sas_url;
        }
      }
      console.log("++++ getSasUrlByFilename, videoSasUrl:", videoSasUrl);
      return videoSasUrl;
    },
    findValueByKeyInArray(array, key, valueToFind) {
      let element = {};
      for (let i = 0; i < array.length; i++) {
        if (array[i][key] == valueToFind) {
          element = array[i];
          break;
        }
      }
      return element;
    },
    getDeliverableVideoOriginal(array, key) {
      let str = "";
      let index, existsInString;
      let element = array[0];
      for (let i = 0; i < array.length; i++) {
        str = array[i][key];
        index = str.indexOf(this.defaultOriginalStr);
        existsInString = !!~index;
        if (existsInString) {
          element = array[i];
          break;
        }
      }
      return element;
    },
    compareValuesObjKey(key, order = "asc") {
      return function innerSort(a, b) {
        if (
          !Object.prototype.hasOwnProperty.call(a, key) ||
          !Object.prototype.hasOwnProperty.call(b, key)
        ) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    },
    generateUUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
          16
        )
      );
    },
  },
  beforeMount() {
    // http://localhost:8081/umvp/0e74e976-4bb0-496e-8e32-47db3018b7bb
    console.log("++ UUID:", this.generateUUID());
    //get video data
    this.getVideoDataFromAPI(document.referrer);
  },
};
</script>
