import axios from "axios";
import router from "@/router";

let BASEURL = "";

const BASEURL_API = process.env.VUE_APP_BASEURL_API;

let apiClientAxios = null;

export default {

  createAxiosHeaders(referer) {
    BASEURL = BASEURL_API;
    let apiAxios = null;
    apiAxios = axios.create({
      baseURL: BASEURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Referer: referer,
        "x-referer": referer
      }
    });
    return apiAxios;
  },
  // Get Video by GUID
  async getVideoByGuid(videoGuid, referer) {
    apiClientAxios = this.createAxiosHeaders(referer);
    return await apiClientAxios
      .get("/embed/Videos/getvideo/" + videoGuid)
      .then(
        response => {
          console.log("API getVideoByGuid >>", response);
          return response;
        },
        error => {
          console.error("API getVideoByGuid >> player api error >>", error);
          router.push({ name: "uvin-medialive-video-player-not-found" });
          return error.response;
        }
      );
  },

}